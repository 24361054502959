<template>
  <div class="report-parts-wrapper ml-3">
    <div class="d-flex align-items-center justify-content-between mt-5">
      <h4 class="chart-title mt-3">
        BLUETT BODYWORKS
      </h4>
    </div>

    <div class="report-table mt-3">
      <iframe title="Report Section" width="1024" height="804" src="https://app.powerbi.com/view?r=eyJrIjoiZGQzMTRiNTgtMDRhMy00NzEwLWI5NjQtZTBjMWU3M2Q2MjRmIiwidCI6IjZlODg3NGQ0LTE2NWUtNDBhNi1hMjJiLTdmYTdjZTAwMjQ0NCJ9&pageName=ReportSection76eb93880ded4801da92" frameborder="0" allowFullScreen="true"></iframe>
    </div>
  </div>
</template>

<script>

export default {
  name: "customBusiness184",
  data() {
    return {}
  },
}
</script>

<style scoped>
.chart-title {
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  color: #1C1F39;
  margin-left: 40px;
}

.report-parts-wrapper {
  width: calc(100vw - 435px);
}

.report-parts-wrapper .dropdown-options.show .dropdown-arrow .bx-down-arrow {
  transform: rotate(0.5turn);
}

.report-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
}
</style>
